import React from "react";
import "./experience.css";
import { BsFillCheckCircleFill } from "react-icons/bs";
import Divider from "../Divider/Divider";

const Experience = () => {
  return (
    <section id="experience">
      <Divider />
      <h2>Experience</h2>
      <div className="container experience-container">
        <div className="experience-frontend">
          <h3>Frontend Development</h3>
          <div className="experience-content">
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>HTML</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>CSS</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>JavaScript</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>React | Next.js</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>Tailwind</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>Bootstrap</h4>
              </div>
            </article>
          </div>
        </div>
        {/******* End of Frontend ******/}
        <div className="experience-backend">
          <h3>Backend Development</h3>
          <div className="experience-content">
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>Node JS</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>Express</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>MongoDB</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>MySQL</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>GraphQL</h4>
              </div>
            </article>
            <article className="experience-details">
              <BsFillCheckCircleFill className="experience-details-icon" />
              <div>
                <h4>REST API</h4>
              </div>
            </article>
          </div>
        </div>
        {/******* End of Backend ******/}
      </div>
    </section>
  );
};

export default Experience;
