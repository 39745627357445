import React from "react";
import './divider.css';

const Divider = () => {
    return (
       
         <div className="astrodivider">
            <div className="astrodividermask">
            </div>
            <span><i>&#10038;</i></span>
        </div>
     
    );
    }

export default Divider;